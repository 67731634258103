import {
  Button,
  ButtonVariant,
  Field,
  Input,
  Link,
  NotificationVariant,
  Text,
  TextVariant,
} from "@lookiero/react-ui-kit";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Promocode, isShopPromoCode } from "@domain/promocode";
import { useEnvironment } from "@hooks/useEnvironment";
import { ROUTES, useNavigator } from "@router";
import { PromocodeApplication } from "@workflow";

import PromocodeBulkStatus from "../../components/PromocodeBulkStatus/PromocodeBulkStatus";
import PromocodeForm, { FormFields } from "../../components/PromocodeForm/PromocodeForm";
import TEXT_FORM from "../../components/PromocodeForm/PromocodeForm.intl.json";
import { FIELD } from "../../components/PromocodeForm/PromocodeForm.schema";
import {
  formValuesToPrimitivePromocode,
  promocodeToFormValues,
} from "../../components/PromocodeForm/utils/formValuesParser";

import { Stack } from "@components/layouts/Stack/Stack";
import { useToastService } from "@components/layouts/ToastProvider/useToast";
import { useAuth } from "@hooks/useAuth";
import "./PromocodeEdit.css";
import TEXT from "./PromocodeEdit.intl.json";

const PromocodeEdit: React.FC = () => {
  const [promocode, setPromocode] = useState<Promocode | null>(null);

  const { promocodeKey } = useParams<{ promocodeKey: string }>();
  const navigator = useNavigator();
  const toast = useToastService();
  const { featureFlags } = useEnvironment();
  const { hasPromocodesWriteRole } = useAuth();
  const authorized = hasPromocodesWriteRole();

  useEffect(() => {
    (async () => {
      const getPromocode = await PromocodeApplication.get("get");

      getPromocode(parseInt(promocodeKey))
        .then(setPromocode)
        .catch(() => {
          navigator.goTo(ROUTES.PROMOCODES);
        });
    })();
  }, [promocodeKey, navigator]);

  const startDateDisabled = Boolean(promocode?.startDate);
  const endDateDisabled = Boolean(promocode?.endDate);

  const handleSubmit = async (values: FormFields) => {
    const editPromocode = await PromocodeApplication.get("edit");
    await editPromocode(Number(promocodeKey), formValuesToPrimitivePromocode(values, featureFlags));
    toast.push(<>{TEXT.TOAST_SUCCESS}</>, { variant: NotificationVariant.SUCCESS });
    navigator.goTo(ROUTES.PROMOCODES);
  };

  if (!authorized) return null;

  return promocode ? (
    <div className="promocode-edit">
      <PromocodeBulkStatus />
      <PromocodeForm
        header={
          <>
            <Text tag="h1" variant={TextVariant.HEADING_1}>
              {TEXT.TITLE}
            </Text>
            <Stack flexDirection="row" spacing="xxs" alignItems="center">
              <Link to={ROUTES.PROMOCODES.path}>
                <Button variant={ButtonVariant.BASIC} className="promocode-edit-buttons">
                  {TEXT.CANCEL_BTN}
                </Button>
              </Link>
              <Button variant={ButtonVariant.PRIMARY} className="promocode-edit-buttons">
                {TEXT.SAVE_BTN}
              </Button>
            </Stack>
          </>
        }
        defaultValues={promocodeToFormValues(promocode)}
        promocodeIdentField={({ inputProps }) => (
          <Field label={TEXT_FORM.PROMOTION_LBL}>
            <Input {...inputProps(FIELD.NAME, TEXT_FORM.PROMOTION_PH)} disabled />
          </Field>
        )}
        onSubmit={handleSubmit}
        disabledFields={{
          [FIELD.START_DATE]: startDateDisabled,
          [FIELD.END_DATE]: endDateDisabled,
          [FIELD.SITES]: true,
          [FIELD.TYPE]: true,
          [FIELD.SEGMENTS]: true,
        }}
        disabled={isShopPromoCode(promocode) && !featureFlags.SHOP_PROMOS}
      />
    </div>
  ) : null;
};

export default PromocodeEdit;

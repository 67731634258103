export type SegmentsObject = { WOMEN?: boolean; MEN?: boolean };

export const toSegmentsList = (segments: SegmentsObject = {}): string[] =>
  Object.entries(segments)
    .filter(([segment, isActive]) => isActive)
    .map(([key]) => key);

export const toSegmentsObject = (segments: string[]): SegmentsObject =>
  segments.reduce<SegmentsObject>(
    (acc, segment) => ({
      ...acc,
      [segment]: true,
    }),
    {},
  );
